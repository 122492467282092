const searchQueryIndex = `
  query Search($query: String!, $keywords: String!) {
    unitCategories(
      filter: {
        where: {
          _or: [
            { slug: { _iLike: $query } }
            { title: { _iLike: $query } }
            { keywords: { _iLike: $query } }
          ]
        }
        limit: 20
      }
    ) {
      id
      slug
      title
      iconName
    }
    unitConversions(
      filter: {
        where: {
          _or: [
            { slug: { _iLike: $query } }
            { title: { _iLike: $query } }
            { keywords: { _iLike: $keywords } }
          ]
        }
        limit: 20
      }
    ) {
      id
      slug
      keywords
      title
      unitCategory {
        id
        slug
      }
    }
  }
`;

// Search on main
const fetchDataIndex = async (query) => {
  try {
    const response = await fetch('https://api.oneconvert.com/graphql', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: searchQueryIndex,
        variables: {
          query: `%${query}%`,
          keywords: `%${query.replace(' to ', ',')}%`,
        },
      }),
    });
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    throw new Error(error.message || 'An error occurred');
  }
};

function calculatePercentageMatch({ slug, title, keywords }, searchWords) {
  const slugWords = slug.toLowerCase().split(/[ -]/u);
  const titleWords = title.toLowerCase().split(' ');
  const keyWords = keywords ? keywords.toLowerCase().split(',') : [];

  const searchSlug = searchWords.toLowerCase();

  let matchCount = 0;

  slugWords.forEach((word) => {
    if (searchSlug.includes(word)) {
      matchCount++;
    }
  });
  titleWords.forEach((word) => {
    if (searchSlug.includes(word)) {
      matchCount++;
    }
  });
  keyWords.forEach((word) => {
    if (searchSlug.includes(word)) {
      matchCount++;
    }
  });

  const percentMatch = (matchCount / (slugWords.length + titleWords.length + keyWords.length)) * 100;

  return percentMatch;
}

const searchInput = document.getElementById('globalSearch');
const resultIndexWrapper = document.getElementById('resultIndexWrapper');

function handleFetchedData(dataArray) {
  const ulElement = document.getElementById('searchResultIndex');
  const divElement = document.getElementById('divElement');

  ulElement.innerHTML = '';

  if (dataArray.length > 0 && dataArray !== 'empty') {
    resultIndexWrapper.classList.remove('hidden');
    divElement.classList.add('hidden');
  } else if (dataArray === 'empty') {
    resultIndexWrapper.classList.add('hidden');
  } else if (dataArray.length === 0) {
    resultIndexWrapper.classList.remove('hidden');
    divElement.textContent = 'No data found';
    divElement.classList.remove('hidden');
  } else {
    resultIndexWrapper.classList.remove('hidden');
    divElement.classList.remove('hidden');
  }

  if (dataArray !== 'empty') {
    dataArray?.forEach((item) => {
      const liElement = document.createElement('li');
      const aElement = document.createElement('a');
      const divElement = document.createElement('div');
      const textNode = document.createTextNode(`${item.title}`);
      const categorySlug = item.unitCategory ? `/${item.unitCategory.slug}` : '';

      aElement.setAttribute('title', item.title);
      aElement.setAttribute('class', 'flex justify-start items-center p-3 hover:bg-blue-50 transition text-left text-sm md:text-base');
      aElement.setAttribute('href', `https://oneconvert.org/unit-converters${categorySlug}/${item.slug}`);

      divElement.setAttribute('class', 'mr-2 flex size-5 flex-shrink-0 items-center justify-center');
      divElement.innerHTML =
        '<div class="[&amp;_path]:bg-white flex justify-center items-center"><svg class="size-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" stroke-linecap="round" stroke-linejoin="round"></path></svg></div>';

      aElement.appendChild(divElement);
      aElement.appendChild(textNode);
      liElement.appendChild(aElement);
      ulElement.appendChild(liElement);
    });
  }
}

if (searchInput) {
  searchInput.addEventListener('input', (event) => {
    const query = event.target.value.trim();
    const svgIcon = document.getElementById('deleteSearchValueIndex');

    if (query.length > 0) {
      svgIcon.classList.remove('hidden');
    } else {
      svgIcon.classList.add('hidden');
      handleFetchedData('empty');
      resultIndexWrapper.classList.add('hidden');
    }

    function clearInput() {
      searchInput.value = '';
      svgIcon.classList.add('hidden');
      handleFetchedData('empty');
      resultIndexWrapper.classList.add('hidden');
    }

    svgIcon.addEventListener('click', clearInput);

    fetchDataIndex(query)
      .then((response) => {
        const responseData = [...response.data.unitCategories, ...response.data.unitConversions];

        responseData.forEach((obj) => {
          const percentageMatch = calculatePercentageMatch(obj, query);

          obj.percentageMatch = percentageMatch;
        });

        const sortSearchPages = responseData.sort((a, b) => b.percentageMatch - a.percentageMatch);

        if (query.length > 0) {
          handleFetchedData(sortSearchPages);
        } else {
          resultIndexWrapper.classList.add('hidden');
          handleFetchedData('empty');
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  });
}

const searchWrapperIndex = document.getElementById('searchWrapperIndex');

const closeDropdownIndexSearch = (event, searchWrapperIndex) => {
  const isClickInsidIndexSearch = searchWrapperIndex.contains(event.target);

  if (!isClickInsidIndexSearch) {
    resultIndexWrapper.classList.add('hidden');
  }
};

document.addEventListener('click', (event) => {
  if (searchWrapperIndex) {
    closeDropdownIndexSearch(event, searchWrapperIndex);
  }
});
